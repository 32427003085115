<template>
 
 <div class="d-none"></div>

</template>

<script setup>

import { onBeforeMount, onMounted } from 'vue'
import { useCookieWowStore } from '@/stores/cookieWow'

const nuxtApp = useNuxtApp();

const cookieDefault = ref(false)
const cookieWow = ref(false)

// ดึง Store สำหรับการจัดการ CookieWow
const cookieWowStore = useCookieWowStore()

const fetchCookie = async () => {

  await cookieWowStore.fetchCookieWowId()
  cookieDefault.value = !cookieWowStore && cookieWowStore.cookieWowId != null ? false : true
  cookieWow.value = cookieWowStore.cookieWowId != null && cookieWowStore.gtm != null

  if (cookieWowStore.cookieWowId && cookieWowStore.gtm) {
    cookieWowStore.injectCookieWowScript(cookieWowStore.cookieWowId, cookieWowStore.gtm)
  }
}

nuxtApp.hook('page:finish', fetchCookie);

// ดึงข้อมูล cookieWowId เมื่อ component ถูก mount
onMounted(async () => {
  // await fetchCookie()
})

</script>

<style scoped>
/* สไตล์เพิ่มเติมถ้าจำเป็น */
</style>