<template>
  <ul class="navbar-nav navbar-nav--main" :class="{ 'navbar-nav--white': isHeaderWhite && isXlScreen }" ref="nav">

    <li class="nav-item" v-for="(item, i) in getMenus[0]?.children" :key="i"
      :class="!item.tags.includes('hidden-submenu') && item.children.length ? 'dropdown' : ''">
      <NuxtLink v-if="!item.tags.includes('hidden-menu')" :to="localePath(item.url)" class="nav-link"
        :class="{ 'dropdown-toggle': !item.tags.includes('hidden-submenu') && item.children.length }" :role="item.children.length ? 'button' : ''"
        :data-bs-toggle="!item.tags.includes('hidden-submenu') && item.children.length ? 'dropdown' : null"
        :aria-expanded="!item.tags.includes('hidden-submenu') && item.children.length ? 'false' : null"
        @click="!item.tags.includes('hidden-submenu') && item.children.length ? null : $emit('link-clicked')">
        {{ item.name }}
      </NuxtLink>

      <div class="dropdown-menu dropdown-menu--lv2" v-if="!item.tags.includes('hidden-submenu') && item.children.length">
        <div class="dropdown-item" v-for="(child, j) in item.children" :key="j">
          <NuxtLink class="dropdown-link" :to="localePath(child.url)" :target="child.target" @click="$emit('link-clicked')">
            {{ child.name }}
            <span class="dropdown-icon">
              <i class="icon-arrow-button"></i>
            </span>
          </NuxtLink>
        </div>
      </div>
    </li>
    <li class="animation" ref="animation"></li>
  </ul>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useFetch, useRuntimeConfig } from "#app";
import { useLocalStorage } from "@/composables/useLocalStorage";

const getMenus = ref([]);
const nav = ref(null);
const animation = ref(null);
const config = useRuntimeConfig();
const baseApi = config.public.baseApi;
const { locale } = useI18n();
const { setItemWithExpiry, getItemWithExpiry } = useLocalStorage();
const isHeaderWhite = ref(false);
const isNavbarWhite = ref(false)
const isXlScreen = ref(false)

// ฟังก์ชันตรวจสอบ HeaderWhite
const checkHeaderWhite = () => {
  const headerWhiteElements = document.querySelectorAll('#HeaderWhite')
  if (!headerWhiteElements.length) {
    isHeaderWhite.value = false
    return
  }

  isHeaderWhite.value = Array.from(headerWhiteElements).some(element => {
    const rect = element.getBoundingClientRect()
    return rect.top <= 80 && rect.bottom >= 0
  })
}

const checkNavbarWhite = () => {
  // เช็คขนาดหน้าจอก่อน ถ้าน้อยกว่า xl (1200px) จะไม่ทำงาน
  if (window.innerWidth < 1200) {
    isNavbarWhite.value = false
    return
  }

  const navbarWhiteElements = document.querySelectorAll('#NavbarWhite')
  if (!navbarWhiteElements.length) {
    isNavbarWhite.value = false
    return
  }

  isNavbarWhite.value = Array.from(navbarWhiteElements).some(element => {
    const rect = element.getBoundingClientRect()
    return rect.top <= 80 && rect.bottom >= 0
  })
}

// เช็คขนาดหน้าจอ
const checkScreenSize = () => {
  isXlScreen.value = window.innerWidth >= 1200 // 1200px คือขนาด xl
}

const fetchMenu = async () => {

  const cacheKey = 'main-menu-v2';
  const cachedMenu = getItemWithExpiry(cacheKey);

  if (cachedMenu) {
    getMenus.value = cachedMenu;
  } else {
    const apiUrl = `${baseApi}/${locale.value}/menus/main`;
    const { data, error } = await useFetch(apiUrl);

    if (!error.value) {
      getMenus.value = data.value.data;
      setItemWithExpiry(cacheKey, data.value.data, 60);
    } else {
      console.error("Error fetching menu data:", error.value);
    }
  }

}

onMounted(async () => {
  // เช็คขนาดหน้าจอครั้งแรก
  checkScreenSize()

  await fetchMenu();
  await nextTick();

  // จัดการ animation และ events
  if (nav.value) {
    const navLinks = nav.value.querySelectorAll("li");

    const setInitialWidth = () => {
      navLinks.forEach((navItem) => {
        navItem.classList.remove("active");
      });

      const currentPath = window.location.pathname;
      const pathSegments = currentPath.split('/').filter(Boolean);

      // ตรวจสอบว่ามี path segments หรือไม่
      if (pathSegments.length >= 2) {
        const basePath = `/${pathSegments[0]}/${pathSegments[1]}`;

        const matchingNavItem = Array.from(navLinks).find(navItem => {
          const link = navItem.querySelector('.nav-link');
          if (link) {
            const href = link.getAttribute('href');
            return href && href.startsWith(basePath);
          }
          return false;
        });

        if (matchingNavItem) {
          const rect = matchingNavItem.getBoundingClientRect();
          const navRect = nav.value.getBoundingClientRect();
          const leftPosition = rect.left - navRect.left;

          animation.value.style.width = `${rect.width}px`;
          animation.value.style.transform = `translateX(${leftPosition}px)`;

          matchingNavItem.classList.add("active");
        } else {
          // ถ้าไม่มีลิงก์ที่ตรงกัน ให้ตั้งค่า animation เป็น 0
          animation.value.style.width = '0';
          console.log("No matching link found, setting animation width to 0");
        }
      } else {
        // ถ้าไม่มี path segments ให้ตั้งค่า animation เป็น 0
        animation.value.style.width = '0';
        console.log("No path segments found, setting animation width to 0");
      }
    };

    const handleMouseOver = (link) => {
      navLinks.forEach((navItem) => {
        navItem.classList.remove("active");
      });

      link.classList.add("active");

      const rect = link.getBoundingClientRect();
      const navRect = nav.value.getBoundingClientRect();
      const leftPosition = rect.left - navRect.left;

      requestAnimationFrame(() => {
        animation.value.style.width = `${rect.width}px`;
        animation.value.style.transform = `translateX(${leftPosition}px)`;
      });
    };

    const handleMouseLeave = () => {
      // เมื่อเมาส์ออกจาก nav ให้ตั้งค่า animation กลับไปที่ active item
      setInitialWidth();
    };

    const handleClick = (link) => {
      navLinks.forEach((navItem) => {
        navItem.classList.remove("active");
      });
      const clickedItem = link.closest('li');
      if (clickedItem) {
        clickedItem.classList.add("active");

        const rect = clickedItem.getBoundingClientRect();
        const navRect = nav.value.getBoundingClientRect();
        const leftPosition = rect.left - navRect.left;

        requestAnimationFrame(() => {
          animation.value.style.width = `${rect.width}px`;
          animation.value.style.transform = `translateX(${leftPosition}px)`;
        });
      } else {
        // ถ้าไม่มีลิงก์ที่ถูกคลิก ให้ตั้งค่า animation เป็น 0
        animation.value.style.width = '0';
        console.log("No link clicked, setting animation width to 0");
      }
    };

    setInitialWidth();

    // Event listeners
    navLinks.forEach((link) => {
      link.addEventListener("mouseover", () => handleMouseOver(link));
    });

    nav.value.addEventListener("mouseleave", handleMouseLeave);

    nav.value.addEventListener("click", (event) => {
      const target = event.target.closest(".nav-link, .dropdown-link");
      if (target) {
        handleClick(target);
      }
    });

    // Router watcher
    const router = useRouter();
    router.afterEach(() => {
      nextTick(() => {
        setInitialWidth();
      });
    });

    // Scroll listener
    checkHeaderWhite();
    window.addEventListener('scroll', checkHeaderWhite, { passive: true });
    checkNavbarWhite();
    window.addEventListener('scroll', checkNavbarWhite, { passive: true });
    window.addEventListener('resize', checkNavbarWhite, { passive: true });
  }

  // เพิ่ม event listener สำหรับ resize
  window.addEventListener('resize', checkScreenSize)
})

// Cleanup
onUnmounted(() => {
  window.removeEventListener('scroll', checkHeaderWhite);
  window.removeEventListener('scroll', checkNavbarWhite)
  window.removeEventListener('resize', checkNavbarWhite)
  window.removeEventListener('resize', checkScreenSize)
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app";

@each $lang in $languages {
  html[lang="#{$lang}"] {
    .navbar-nav {
      &.navbar-nav--main {
        .nav-link {
          @include font-family("body", $lang);
          @include font-size("small", $lang);
          @include font-weight("meduim", $lang);

          @include media-breakpoint-down(lg) {
            @include font-size("body-2", $lang);
          }
        }

        .dropdown-menu {
          .dropdown-item {
            .dropdown-link {
              @include font-size("body", $lang);

              @include media-breakpoint-down(lg) {
                @include font-size("small", $lang);
              }
            }
          }
        }
      }
    }
  }
}

.navbar-nav {
  &.navbar-nav--main {
    position: relative;
    height: 100%;
    gap: 24px;
    @include transition(all 0.6s ease-in-out 0.3s);


    .nav-item {
      position: unset;
      text-align: center;

      @include media-breakpoint-down(lg) {
        text-align: start;
      }

      .nav-link {
        color: $black;
        padding: 0.39rem 1.36rem;
        position: relative;
        z-index: 1;
        box-shadow: none;

        @include media-breakpoint-down(sm) {
          padding: 0.39rem 1rem;
        }


        &.dropdown-toggle {
          &::after {
            content: '+';
            padding-left: 0.65rem;
          }
        }
      }

      &.active {
        .nav-link {
          color: $white;

          @include media-breakpoint-down(lg) {
            color: $gray-1;
          }
        }
      }

      &.dropdown {
        // &.show,
        // &.active {
        //   > .nav-link {
        //     color: $white;
        //   }
        // }

        .dropdown-menu {
          height: auto;
          width: 100%;
          flex-direction: column;
          padding: 1.45rem 0.938rem;
          border-radius: 24px;
          background-color: $white;
          margin-top: 0.90rem;
          -moz-column-count: 2;
          column-count: 2;
          page-break-inside: avoid;
          -moz-column-break-inside: avoid;
          break-inside: avoid-column;

          @include media-breakpoint-down(lg) {
            column-count: 1;
          }

          &.dropdown-menu--lv2 {
            border-radius: 16px;
            box-shadow: 0px 4px 10px 0px rgba($black, 0.15);

            @include media-breakpoint-down(lg) {
              box-shadow: none;
              padding: 0 1.75rem;
              margin-top: 0;
            }

            .dropdown-item {
              position: relative;
              display: flex;
              flex-direction: column;
              width: 100%;
              padding: 0;
              text-transform: capitalize;
            }

            .dropdown-link {
              position: relative;
              display: flex;
              padding: 0.625rem 0.75rem 0.625rem;
              white-space: normal;
              break-inside: avoid;
              color: $black;
              background-color: transparent;
              cursor: pointer;
              @include transition();

              @include media-breakpoint-down(lg) {
                &::before {
                  position: absolute;
                  content: "";
                  left: 0;
                  top: 18px;
                  bottom: 0;
                  width: 6px;
                  height: 6px;
                  background-color: $gray-1;
                  border-radius: 50%;
                }
              }

              .dropdown-icon {
                margin-left: 10px;
                @include transition();

                i {
                  font-size: px-to-rem(10);
                  font-weight: normal;
                  transform: rotate(-45deg);
                }
              }

              &.router-link-active {
                color: $gray-1;

                .dropdown-icon {
                  transform: translateX(0.65rem);
                  @include transition();

                  i {
                    color: $gray-1;
                  }
                }
              }

              @include hover-focus-active {
                color: $gray-1;

                .dropdown-icon {
                  transform: translateX(0.65rem);
                  @include transition();

                  i {
                    color: $gray-1;
                  }
                }
              }
            }
          }
        }


      }
    }

    &.navbar-nav--white {
      .nav-item {
        .nav-link {
          color: $white;
          ;
        }

        &.active {
          .nav-link {
            color: $black;
          }
        }
      }

      .animation {
        background-color: $white;
      }
    }
  }
}

.animation {
  position: absolute;
  height: 100%;
  top: 0;
  border-radius: 25px;
  width: 0;
  color: $white;
  background-color: $black;
  transform: translateX(0);
  z-index: -1;

  @include transition();

  @include media-breakpoint-down(lg) {
    display: none;
  }
}
</style>
