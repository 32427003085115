<template>

    <LayoutsHeader />

    <main class="main">

        <slot />

    </main>

    <LayoutsFooter />

    <LayoutsCookieDefault />

</template>

<script setup>


const { locale } = useI18n();
const config = useRuntimeConfig();
const appUrl = config.public.baseApi;


useHead({

    htmlAttrs: {
        lang: locale
    },
    link: [
        {
            rel: "shortcut icon",
            href: `/static/images/favicon.png`
        },
    ]

})

const onScroll = () => {
    if (window.scrollY < 100) {
      document.body.classList.remove("is-black");
    }
};

onMounted(() => {
  if (document.body) {
    window.addEventListener("scroll", onScroll);
  }
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", onScroll);
});

</script>


<style lang="scss">

@import '/assets/fonts/anuphan/stylesheet.css'; 
@import '/assets/fonts/meno-banner/font-face.css'; 
@import '/assets/fonts/inter/stylesheet.css'; 
/* icons */
@import '/assets/fonts/icons/style.css'; 
@import '/assets/vendor/fontawesome/css/all.css';

@import '/assets/scss/index.scss';

//custom for p in api
@each $lang in $languages {
  html[lang="#{$lang}"] {
      p {
        @include font-size("body", $lang);
      }
  }
}

body {
    padding: 0;
    margin: 0;
}

</style>
