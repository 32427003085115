// stores/cookieWow.js
import { defineStore } from "pinia";
import { useFetch, useRuntimeConfig } from "#app";

export const useCookieWowStore = defineStore("cookieWow", {
  state: () => ({
    cookieWowId: null,
    gtm: null,
    isLoading: false,
    isShow: false,
    error: null,
  }),
  actions: {
    async fetchCookieWowId() {
      this.isLoading = true;
      this.error = null;

      const config = useRuntimeConfig();
      const baseApi = config.public.baseApi;

      try {
        // Fetch the CookieWow ID from the API พร้อมเพิ่ม Origin Header
        const { data, error: fetchError } = await useFetch(
          `${baseApi}/en/consent/cookiewow`,
          {
            headers: {
              Origin: window.location.origin, // ✅ ส่ง origin ไปที่ API
              "Content-Type": "application/json",
            },
          }
        );

        if (fetchError.value) {
          throw new Error("Error fetching CookieWow ID");
        }

        this.cookieWowId = data.value.data.cookie_wow;
        this.gtm = data.value.data.gtm;
      } catch (err) {
        this.error =
          err.message || "An error occurred while fetching the CookieWow ID.";
      } finally {
        this.isLoading = false;
      }
    },

    injectCookieWowScript(id, gtm) {
      const loadScript = (src, isConfig = false, id = "", attributes = {}) => {
        return new Promise((resolve, reject) => {
          const scriptElement = document.createElement("script");
          scriptElement.type = "text/javascript";
          scriptElement.src = src;
          scriptElement.nonce = "";

          if (id) {
            scriptElement.id = id;
          }

          if (isConfig) {
            scriptElement.setAttribute("data-cwcid", attributes.cwcid);
          }

          scriptElement.onload = resolve; // Resolve when script is loaded
          scriptElement.onerror = reject; // Reject if there is an error loading the script

          document.head.append(scriptElement);
        });
      };

      // Function to load script with OPTIONS preflight
      const loadScriptWithOptions = (
        src,
        isConfig = false,
        id = "",
        attributes = {}
      ) => {
        return new Promise((resolve, reject) => {
          // ส่ง OPTIONS request พร้อม Origin Header
          const xhr = new XMLHttpRequest();
          xhr.open("GET", src, true);
          // xhr.open("OPTIONS", src, true);
          xhr.setRequestHeader("Origin", window.location.origin); // ✅ เพิ่ม Origin
          xhr.onload = () => {
            if (xhr.status === 200 || xhr.status === 204) {
              // ถ้า OPTIONS request สำเร็จ ให้โหลด script
              const scriptElement = document.createElement("script");
              scriptElement.type = "text/javascript";
              scriptElement.src = src;
              scriptElement.defer = true;
              scriptElement.nonce = "";

              if (id) {
                scriptElement.id = id;
              }

              if (isConfig) {
                scriptElement.setAttribute("data-cwcid", attributes.cwcid);
              }

              scriptElement.onload = resolve;
              scriptElement.onerror = reject;
              document.head.append(scriptElement);
            } else {
              reject(new Error(`GET request failed for ${src}`));
            }
          };
          xhr.onerror = () =>
            reject(new Error(`GET request failed for ${src}`));
          xhr.send();
        });
      };

      // Inject CookieWow and GTM scripts sequentially
      loadScript("https://cookiecdn.com/cwc.js");
      loadScript(`https://cookiecdn.com/configs/${id}`, true, "cookieWow", {
        cwcid: id,
      })
        .then(() => {
          return loadScriptWithOptions(
            `https://cookiecdn.com/configs/${id}`,
            true,
            "cookieWow",
            { cwcid: id }
          );
        })
        .then(() => {
          // Load GTM
          const script = document.createElement("script");
          script.id = "gtm-script";
          script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${gtm}');`;
          document.head.prepend(script);

          // Inject noscript iframe for GTM
          const iframe = document.createElement("noscript");
          iframe.id = "gtm-noscript";
          iframe.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtm}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
          document.body.prepend(iframe);
        })
        .catch((error) => {
          console.error("Error loading scripts: ", error);
        });
    },
  },
});