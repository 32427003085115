<template>
  <div class="nav nav--policy">
    <template v-if="getMenus && getMenus.length">
      <template v-for="(item, i) in getMenus[0]?.children" :key="i">
        <NuxtLink :to="localePath(item.url)" prefetch-on="interaction" class="nav-link">
          {{ item.name }}
        </NuxtLink>
      </template>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useFetch, useRuntimeConfig } from '#app';
import { useLocalStorage } from '@/composables/useLocalStorage';  // นำเข้า useLocalStorage

// สร้าง state เพื่อเก็บข้อมูลเมนู
const getMenus = ref([]);

// ใช้ localePath สำหรับ nuxt-link
const localePath = useLocalePath();

// ดึงค่า baseApi จาก nuxt.config.ts
const config = useRuntimeConfig();
const baseApi = config.public.appApi;

// ดึง locale จาก i18n
const { locale } = useI18n();

// ใช้ useLocalStorage
const { setItemWithExpiry, getItemWithExpiry } = useLocalStorage();

const fetchMenu = async () => {

  const cacheKey = 'footer-menu-v1';
  const cachedMenu = getItemWithExpiry(cacheKey);

  if (cachedMenu) {
    getMenus.value = cachedMenu;
  } else {
    const apiUrl = `${baseApi}/${locale.value}/menus/footer`;
    const { data, error } = await useFetch(apiUrl);

    if (!error.value) {
      getMenus.value = data.value.data;
      setItemWithExpiry(cacheKey, data.value.data, 60);
    } else {
      console.error("Error fetching menu data:", error.value);
    }
  }

}

// ใช้ onMounted เพื่อดึงข้อมูลจาก API เมื่อ component ถูกสร้างขึ้น
onMounted(async () => {
  await fetchMenu()
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app";

.nav {
  &.nav--policy {
    display: flex;
    gap: 14px;
    justify-content: end;

    .nav-link {
      padding: 0;
      color: rgba($white, 0.5);
      text-decoration: none;
      @include transition();

      @include hover-focus-active {
        color: $yellow-ci;
      }
    }

    @include media-breakpoint-down(lg) {
      justify-content: center;
      margin-top: 1rem;
    }

  }
}
</style>
