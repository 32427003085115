<template>
   <div class="container-fluid">
      <div class="row">
         <div class="col">
            <div class="navbar-nav navbar-nav--hamburger collapse" id="menuHamburger">
               <LayoutsMenusMain @link-clicked="handleLinkClick" />
               <div class="navbar-nav__button" ref="shinyButton">
                  <NuxtLink class="btn btn--link" :to="localePath('/contact-us')" target="_self">
                     <span class="btn__title"> {{ translate('contact.us') }}</span>
                     <span class="btn__icon"><i class="icon-arrow-button"></i></span>
                  </NuxtLink>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>


<script setup>
import { ref } from 'vue';
import { useTranslationStore } from '@/stores/translations';
import { useShinyButton } from '@/composables/useShinyButton';


const translationStore = useTranslationStore()
const translate = translationStore.translate
const { shinyButton } = useShinyButton()


const handleLinkClick = () => {
   const menu = document.getElementById('menuHamburger');
   const toggler = document.querySelector('.navbar-toggler');

   if (menu) {
      // ลบคลาส show จากเมนู
      menu.classList.remove('show');

      // เปลี่ยนสถานะของ navbar-toggler
      if (toggler) {
         toggler.classList.add('collapsed'); // เพิ่ม class collapsed
         toggler.setAttribute('aria-expanded', 'false'); // เปลี่ยนค่า aria-expanded
      }
   }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app";

@each $lang in $languages {
   html[lang="#{$lang}"] {
      .navbar-nav {
         &.navbar-nav--hamburger {
            .nav-link {
               @include font-size("body", $lang);
               @include font-weight("meduim", $lang);
            }

            .dropdown-menu {
               .dropdown-item {
                  .dropdown-link {
                     @include font-size("body", $lang);

                     @include media-breakpoint-down(lg) {
                        @include font-size("body-2", $lang);
                     }
                  }
               }
            }
         }
      }
   }
}

.navbar-nav {
   &.navbar-nav--hamburger {
      position: fixed;
      z-index: -1;
      padding: 1rem 0.45rem;
      margin: 6.25rem 1rem;
      border-radius: 16px;
      max-height: calc(100vh - 5rem);
      width: auto;
      top: 0;
      left: 0;
      right: 0;
      column-count: 3;
      page-break-inside: avoid;
      break-inside: avoid-column;
      overflow-y: auto;
      background-color: $white;
      box-shadow: 0px 4px 10px 0px rgba($black, 0.15);
      column-count: unset;
      justify-content: center;
      @include transition();

      @include media-breakpoint-down(md) {
         padding: 1rem 0;
      }

      @include media-breakpoint-down(sm) {
         margin: 5.25rem 0.75rem 0rem;
      }



      .nav-item {
         &:first-child {
            .nav-link {
               color: $dark-gray;

               @include hover-focus-active {
                  color: $yellow-ci;
               }
            }
         }

         &:not(:first-child) {
            margin-top: 1rem;
         }

         @include media-breakpoint-down(md) {
            &:not(:first-child) {
               margin-top: 0;
            }
         }
      }

      .nav-link {
         color: $black;
         position: relative;
         letter-spacing: -0.48px;
         display: flex;
         align-items: center;

         // @include media-breakpoint-down(lg) {

         //     &.dropdown-toggle {

         //         &:after {
         //             @include font-fontawesome('\f105');
         //             @include transition;
         //             font-size: px-to-rem(28);
         //             color: $yellow-ci;
         //             margin-left: 0.75rem;
         //             position: absolute;
         //             right: 0;
         //             border: none;
         //         }
         //         &.show {
         //             &:after {
         //                 @include transform(rotate(90deg));
         //                 color: $yellow-ci;
         //             }
         //         }
         //         &:hover {
         //             &:after {
         //                 color: $yellow-ci;
         //             }
         //         }
         //     }
         // }

         &:focus-visible {
            outline: none;
            outline-color: transparent;
            outline-style: none;
         }
      }


      .dropdown-menu--lv2 {
         position: relative !important;
         display: block;
         margin-bottom: 1rem;

         @include media-breakpoint-down(lg) {
            display: none;

            &.show {
               display: block;
            }
         }
      }


      .dropdown-link {
         white-space: normal;
         color: $black;
         @include transition();

         .dropdown-icon {
            margin-left: 10px;

            i {
               font-size: px-to-rem(16);
               font-weight: normal;
               transform: rotate(-45deg);
               @include transition();
            }
         }

         @include hover-focus-active {
            color: $gray-1;
         }
      }

      .dropdown-item {
         padding: 0.25rem 0.5rem;

         &.active {
            background-color: transparent;
         }

         @include hover-focus-active {
            background-color: transparent;
         }
      }

      .navbar-nav--main {
         @include media-breakpoint-down(lg) {
            flex-direction: column;
            gap: 10px;
         }
      }
   }

   .navbar-nav__button {
      display: none;

      @include media-breakpoint-down(sm) {
         display: block;
         padding: 0.75rem 1rem 0.45rem;
      }

   }
}
</style>
