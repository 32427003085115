<template>
   <header :class="['header', { 'header--white': isHeaderWhite, 'header--home': isHomePage, 'header--content': !isHomePage }, { 'hide': isHeaderHidden }]">
      <div class="header__main">
      <nav class="navbar navbar-expand-xl p-0">
            <div class="container-fluid">
               <div class="row flex-grow-1 justify-content-between align-items-center">
                  <div class="col-auto">
                  <NuxtLink  class="header__logo" :to="localePath('/home')">
                     <img src="/static/images/default/logo-si.svg" alt="shareinvestor-thailand">
                  </NuxtLink>
                  <NuxtLink  class="header__logo header__logo--white" :to="localePath('/home')">
                     <img src="/static/images/default/logo-si-white.svg" alt="shareinvestor-thailand">
                  </NuxtLink>
                  </div>
                  <div class="col">
                     <div class="header__menu" id="menuMain">
                        <LayoutsMenusMain />
                     </div>
                  </div>
                  <div class="col-auto ps-0 ps-sm-auto">
                     <div class="header__option">
                         <div class="header__list">
                               <NuxtLink class="header__item" :to="localePath('/search')" target="_self">
                                 <i class="fas fa-search"></i>
                              </NuxtLink>
                        </div> 
                        <!-- <div class="header__list">
                           <div class="header__item">
                              <a class="header__link active" href="javascript:void(0);">ENG</a>
                           </div>
                        </div> -->
                        <div class="header__button" ref="shinyButton">
                           <NuxtLink class="btn btn--link" :to="localePath('/contact-us')" target="_self">
                              <span class="btn__title"> {{ translate('contact.us') }}</span>
                              <span class="btn__icon"><i class="icon-arrow-button"></i></span>
                           </NuxtLink>
                        </div>
                        <div class="header__icon">
                           <!-- <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#menuHamburger" aria-controls="menuHamburger" aria-expanded="false" aria-label="Toggle navigation" id="menuHamburgerButton"> -->
                              <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#menuHamburger" aria-expanded="false">
                                 <div class="header__wrapper">
                                    <div class="header__line"></div>
                                    <div class="header__line"></div>
                                    <div class="header__line"></div>
                                 </div>
                              </button>
                        </div>
                     </div>
                     <div class="header__hamburger">
                           <LayoutsHamburger />
                     </div>
                  </div>
               </div>
            </div>
      </nav>
      </div>
   </header>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { useTranslationStore } from '@/stores/translations';
import { useShinyButton } from '@/composables/useShinyButton';


const translationStore = useTranslationStore()
const isHeaderWhite = ref(false)
const route = useRoute();

// คอมพิวเตอร์เพื่อเช็คว่าเป็นหน้า Home หรือไม่
const isHomePage = computed(() => {
  return route.path.endsWith('/home'); // ตรวจสอบว่าเส้นทางลงท้ายด้วย '/home'
});

// ฟังก์ชันตรวจสอบ HeaderWhite ทุกจุด
const checkHeaderWhite = () => {
  // เช็คขนาดหน้าจอก่อน ถ้าน้อยกว่า xl (1200px) จะไม่ทำงาน
  if (window.innerWidth < 1200) {
    isHeaderWhite.value = false
    return
  }

  const headerWhiteElements = document.querySelectorAll('#HeaderWhite')
  if (!headerWhiteElements.length) {
    isHeaderWhite.value = false
    return
  }

  isHeaderWhite.value = Array.from(headerWhiteElements).some(element => {
    const rect = element.getBoundingClientRect()
    return rect.top <= 80 && rect.bottom >= 0
  })
}

const isHeaderHidden = ref(false);

const setScrollEvent = () => {
  const header = document.querySelector('.header');
  let lastScrollTop = 0;

  window.addEventListener("scroll", function() {
    const st = window.pageYOffset || document.documentElement.scrollTop;

    if (isHomePage.value && window.innerWidth < 1200) {
      // สำหรับ header--home ในหน้าจอที่มีขนาดน้อยกว่า 1200px
      if (st > lastScrollTop) {
        // เลื่อนลง
        if (!isHeaderHidden.value) {
          isHeaderHidden.value = true; // ซ่อน header
        }
      } else if (st < lastScrollTop) {
        // เลื่อนขึ้น
        if (isHeaderHidden.value) {
          isHeaderHidden.value = false; // แสดง header
        }
      }
    } else if (!isHomePage.value) {
      // สำหรับ header--content ในทุกหน้าจอ
      if (st > lastScrollTop) {
        // เลื่อนลง
        if (!isHeaderHidden.value) {
          isHeaderHidden.value = true; // ซ่อน header
        }
      } else if (st < lastScrollTop) {
        // เลื่อนขึ้น
        if (isHeaderHidden.value) {
          isHeaderHidden.value = false; // แสดง header
        }
      }
    }

    lastScrollTop = st <= 0 ? 0 : st; // สำหรับมือถือหรือการเลื่อนลบ
  }, false);
};

const setMouseEvent = () => {
  const header = document.querySelector('.header');

  document.body.onmousemove = function(e) {
    if (e.clientY <= 15 && isHeaderHidden.value) {
      isHeaderHidden.value = false; // แสดง header
    }
  };
};

onMounted(() => {
  checkHeaderWhite() // เช็คครั้งแรก
  setMouseEvent();
  setScrollEvent();
  
  // เพิ่ม event listeners
  window.addEventListener('scroll', checkHeaderWhite, { passive: true })
  window.addEventListener('resize', checkHeaderWhite, { passive: true }) // เพิ่ม resize listener

  

  // Cleanup
  onUnmounted(() => {

   window.removeEventListener("scroll", setScrollEvent);
   document.body.onmousemove = null; 
    window.removeEventListener('scroll', checkHeaderWhite)
    window.removeEventListener('resize', checkHeaderWhite)


  })
})

// โค้ดส่วนอื่นๆ เหมือนเดิม
const translate = translationStore.translate
const { shinyButton } = useShinyButton()

callOnce(async () => {
  await translationStore.fetchTranslations()
})

// Watcher เพื่อตรวจสอบการเปลี่ยนแปลงของเส้นทาง
watch(route, (newRoute) => {
  console.log('Current route:', newRoute.path);
});
</script>

<style lang="scss">
   @import "@/assets/scss/app";

   //header
   @each $lang in $languages {
      html[lang="#{$lang}"] {
         .header {
               .header__list {
                  @include font-size("small", $lang);
                  @include font-weight("meduim", $lang);

                  @include media-breakpoint-down(sm) {
                     @include font-size("small-2", $lang);
                     @include font-weight("semibold", $lang);
                  }
               }
         }
      }
   }

   .header {
      position: fixed;
      width: 100%;
      padding: 0 18px 0 18px;
      top: 23px;
      z-index: 1030;
      left: 0;
      right: 0;
      margin: 0 auto;
      @include transition();

      
      @include media-breakpoint-down(lg) {
         padding: 0;
         top: 0;
         backdrop-filter: none;
      }

      // @include media-breakpoint-down(md) {
      //    padding: 0px 18px 0 18px;
      // }

      // @include media-breakpoint-down(sm) {
      //    padding: 0 0 0 0;
      // }

      .header__main{
         padding: 0 0.75rem;
            @include media-breakpoint-down(lg) {
               background-color: $white;
               box-shadow: 0px 4px 10px 0px rgba($black, 0.15);
               padding: 0.75rem;
               margin: 1rem;
               border-radius: 30px;
            }
            @include media-breakpoint-down(md) {
               padding: 0.75rem 0.45rem;
            }

            @include media-breakpoint-down(sm) {
               margin: 1rem 0.75rem;
               border-radius: 20px;
            }
      }

      .header__logo {
         display: flex;
         align-items: center;
         position: relative;

         >img {
               max-width: 100%;
               height: auto;
               max-height: 42px;

               @include media-breakpoint-down(lg) {
                  max-height: 36px;
               }
               @include media-breakpoint-down(sm) {
                  max-height: 30px;
               }
               // @include media-breakpoint-down(xs) {
               //    max-height: 25px;
               // }
         }
         @include media-breakpoint-down(lg) {
               justify-content: left;
         }

         &.header__logo--white{
               display: none;
         }
      }

      .header__menu {
         background-color: rgba($black, 0.10);
         padding: 0.52rem 0.75rem;
         border-radius: 25px;
         width: fit-content;
         margin: auto;
         backdrop-filter: blur(5px);

         @include media-breakpoint-down(lg) {
               display: none;
         }
      }

      .header__option {
         position: relative;
         display: flex;
         justify-content: end;
         align-items: center;
         z-index: 1;
      }

      .header__icon {
         position: relative;
         width: 50px;
         height: 50px;
         background-color: rgba($black, 0.10);
         margin-left: 0.65rem;
         border-radius: 50%;
         display: none;
         @include transition();

         @include media-breakpoint-down(lg) {
               display: block;
         }

         @include media-breakpoint-down(sm) {
            width: 38px;
            height: 38px;
            margin-left: 0.30rem;
         }
      }

      .header__button{
         @include media-breakpoint-down(sm) {
            display: none;
         }
      }

      .navbar-toggler {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 0;
            border: 0;
            border-radius: 0;
            outline: none;
            box-shadow: none;


            .header__line {
               position: relative;
               display: block;
               height: 2px;
               width: 30px;
               margin: 4px 0;
               background-color: $black;
               @include transition(.5s);

               @include media-breakpoint-down(sm) {
                     width: 22px;
               }

               &:nth-child(2) {
                  width: 20px;

                  @include media-breakpoint-down(sm) {
                     width: 18px;
                  }

                  &::before, &::after{
                     position: absolute;
                     content: "";
                     background-color: $black;
                     width: 20px;
                     height: 2px;
                     align-items: center;
                     position: absolute;
                     left: 0;
                     opacity: 0;
                     @include transition(.5s);
                     transform: unset;

                     @include media-breakpoint-down(sm) {
                        width: 18px;
                     }
                  
                  }

                
               }

              
            }
            
            .header__wrapper{
               display: block;

               @include hover {
                  .header__line {
                     &:nth-child(2) {
                        width: 30px;

                        @include media-breakpoint-down(sm) {
                           width: 22px;
                        }
                     }
                  }
               }
            }

            &:not(.collapsed) {
               .header__line {
                  margin: 0; 
                  
                  &:nth-child(1) {
                     opacity: 0;
                  }

                  &:nth-child(2) {
                     background-color: transparent;
                        &::before{
                           transform: rotate(45deg);
                           width: 30px;
                           opacity: 1;

                           @include media-breakpoint-down(sm) {
                              width: 22px;
                           }
                        }
                        &::after{
                           transform: rotate(-45deg);
                           width: 30px;
                           opacity: 1;

                           @include media-breakpoint-down(sm) {
                              width: 22px;
                           }
                        }
                  }

                  &:nth-child(3) {
                     opacity: 0;
                  }
               }
            }
         }

      .header__list{
         position: relative;
         display: flex;
         justify-content: center;
         align-items: center;
         background-color: rgba($black, 0.10);
         margin: 0 0.3rem;
         border-radius: 25px;
         height: 50px;
         width: 50px;
         gap: 10px;

         i{
            font-size: 16px;
         }

         @include media-breakpoint-down(sm) {
               height: 38px;
               width: 38px;

               i{
                  font-size: 14px;
               }
         }
      }

      .header__item {
            position: relative;
            margin: 0 5px;
      }

      .header__link {
         display: flex;
         align-items: center;
         color: $black;
         text-decoration: none;

         @include hover-focus-active {
            color: $dark-gray;
         }
      }

      .header__hamburger {
         // position: fixed;
         // right: 0;
         // left: 0;
         // width: 100%;
         // max-height: 85vh;
         // box-shadow: 0px 4px 10px 0px rgba($black, 0.15);
         // border-radius: 0 0 40px 40px;
         // background-color: $white;
         @include transition();
         @include media-breakpoint-up(xl) {
               display: none;
         }

         &.collapsing {
               // background-color: $gray;
               @include transition();
         }
      }

      &.header--white{
         .header__menu{
               background-color: rgba($white, 0.10);
         }
         .header__logo{
               display: none;
               
               &.header__logo--white{
                  display: flex;
               }
         }

         .header__list{
            background-color: rgba($white, 0.10);
            backdrop-filter: blur(5px);

            i{
               color: $white;
               @include hover-focus-active {
                     color: $white;
               }
            }
         }

         .header__icon{
               background-color: rgba($white, 0.10);
               backdrop-filter: blur(5px);
         }
         .navbar-toggler{
            .header__line {
               background-color: $white;

               &:nth-child(2) {
                  background-color: $white;

                  &::before, &::after{
                     background-color: $white;

                  }
               }
            }
         }
        
         .header__link{
            color: $white;

            @include hover-focus-active {
                  color: $white;
            }
         }
      
      }

      &.menu-hamburger-open {
         @include transition();
         background-color: $gray;
      }
      &.header--home{
         &.hide:not(.active) {
            transform: translateY(-100%);
            pointer-events: none;

            .navbar-nav {
               &.navbar-nav--hamburger {
                  transform: translateY(-100%);
                  pointer-events: none;
               }
               .dropdown-menu{
                  &.dropdown-menu--lv2{
                     transform: translateY(-100%);
                     pointer-events: none;
                  }
               }
            }
         }
      }

      &.header--content{
         top: 0;
         padding: 0;

         .header__main{
            background-color: $white;
            box-shadow: 0px 4px 10px 0px rgba($black, 0.15);
            margin: 1rem;
            border-radius: 30px;
            padding: 0.75rem;

            @include media-breakpoint-down(md) {
               padding: 0.75rem 0.45rem;
            }

            @include media-breakpoint-down(sm) {
               border-radius: 20px;
               margin: 1rem 0.75rem;
            }
             
         }
         .navbar-nav {
            &.navbar-nav--main {
               .nav-item{
                  .dropdown-menu{
                     &.dropdown-menu--lv2{
                        @include media-breakpoint-up(xl) {
                           margin-top: 1.75rem;
                        }
                     }
                  }
               }
              
            }
         }

         .header__menu{
            background-color: transparent;
         }
         .animation {
            z-index: 0;
         }

         &.hide:not(.active) {
            transform: translateY(-100%);
            pointer-events: none;

            .navbar-nav {
               &.navbar-nav--hamburger {
                  transform: translateY(-100%);
                  pointer-events: none;
               }
               .dropdown-menu{
                  &.dropdown-menu--lv2{
                     transform: translateY(-100%);
                     pointer-events: none;
                  }
               }
            }
         }
      }

      &.close {
         @include transition();
      }

   }

  


</style>