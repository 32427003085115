<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="footer__logo">
              <a :href="localePath('/home')">
                <img
                  src="/static/images/logo-shareinvestor.svg"
                  alt="shareinvestor-thailand"
                />
              </a>
            </div>
            <ul class="nav nav--social">
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="translate('facebook.url')"
                  target="_blank"
                >
                  <i class="icon-facebook"></i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="translate('twitter.url')"
                  target="_blank"
                >
                  <i class="icon-twitter"></i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="translate('instagram.url')"
                  target="_blank"
                >
                  <i class="icon-instagram"></i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="translate('linkedin.url')"
                  target="_blank"
                >
                  <i class="icon-linkedin"></i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="translate('youtube.url')"
                  target="_blank"
                >
                  <i class="icon-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-7 col-xl-6 offset-xl-1">
            <div class="footer__block">
              <div class="row">
                <div class="col-md-7">
                  <div class="footer__title text-uppercase">
                    {{ translate("location") }}
                  </div>
                  <div class="footer__address">
                    {{ translate("address.detail01") }} <br />
                    {{ translate("address.detail02") }} <br />
                    {{ translate("address.detail03") }} <br />
                    {{ translate("address.detail04") }}
                  </div>

                  <a
                    class="btn btn--text-white"
                    href="https://maps.app.goo.gl/Vp9YhtSNEz7LeewM9"
                    target="_blank"
                  >
                    <span class="btn__title">{{
                      translate("get.direction")
                    }}</span>
                    <span class="btn__icon btn__icon--right"
                      ><i class="icon-arrow-button"></i
                    ></span>
                  </a>
                </div>
                <div class="col-md-5">
                  <div class="footer__nav">
                    <a class="footer__title d-inline-block" href="https://www.alphainvestholdings.com" target="_blank">
                      {{ translate("shareinvestor.group") }}
                    </a>
                    <ul class="nav nav--footer">
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="https://www.shareinvestor.com/sg"
                          target="_blank"
                          >{{ translate("country.singapore") }}</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="https://www.shareinvestor.com/my"
                          target="_blank"
                          >{{ translate("country.malaysia") }}</a
                        >
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="" target="_blank">{{
                          translate("country.thailand")
                        }}</a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="https://www.shareinvestor.co.id/"
                          target="_blank"
                          >{{ translate("country.indonesia") }}</a
                        >
                      </li>
                      <!-- <li class="nav-item">
                        <a class="nav-link" href="" target="_blank">{{
                          translate("country.australia")
                        }}</a>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="footer__tel">
              <i class="icon-phone"></i>
              <a :href="`tel:+` + translate('tel.number')">
                {{ translate("tel.number") }}
              </a>
            </div>
          </div>
          <div class="col-lg-7 col-xl-6 offset-xl-1">
            <div class="footer__block">
              <div class="row">
                <div class="col-md-7">
                  <div class="footer__title text-uppercase">
              {{ translate("contact") }}
            </div>
            <div class="footer__address">
              <a
                class="btn btn--text-white btn--text"
               :href="`mailto:` + translate('email.sale')"
              >
                <span class="btn__title">{{ translate("email.sale") }} </span>
                <span class="btn__icon btn__icon--right"
                  ><i class="icon-arrow-button"></i
                ></span>
              </a>
            </div>
                </div>
                <div class="col-md-5">
                  <div class="footer__nav">
                  <div class="footer__title text-uppercase">
              {{ translate("general.information") }}
            </div>
            <div class="footer__address">
              <a
                class="btn btn--text-white btn--text"
                 :href="`mailto:` + translate('email.admin')"
              >
                <span class="btn__title">{{ translate("email.admin") }} </span>
                <span class="btn__icon btn__icon--right"
                  ><i class="icon-arrow-button"></i
                ></span>
              </a>
            </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 col-xl-6 offset-xl-1">
            <!-- <div class="footer__email">
              <a
                class="btn btn--text-white btn--text-lg"
                href="mailto: sales.th@shareinvestor.com"
                target="_blank"
              >
                <span class="btn__title"> {{ translate("email.sale") }} </span>
                <span class="btn__icon btn__icon--right mt-md-2"
                  ><i class="icon-arrow-button"></i>
                </span>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="footer__copyright">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              {{ translate("copyright") }}
            </div>
            <div class="col-lg-6">
              <div class="footer__menu">
                <LayoutsMenusFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { useTranslationStore } from "@/stores/translations";

// ดึง store สำหรับการแปล
const translationStore = useTranslationStore();

// ดึงข้อมูลการแปลเมื่อ component ถูก mount
callOnce(async () => {
  await translationStore.fetchTranslations();
});

// สร้างฟังก์ชันสำหรับการแปล
const translate = translationStore.translate;
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app";

@each $lang in $languages {
  html[lang="#{$lang}"] {
    .footer {
      @include font-size("small", $lang);
      @include font-weight("medium", $lang);

      .footer__title {
        @include font-weight("bold", $lang);
      }

      .footer__tel {
        @include font-size("h6", $lang);
        @include font-weight("semibold", $lang);
      }

      .footer__email {
        @include font-size("h6", $lang);
        @include font-weight("medium", $lang);
      }

      .footer__copyright {
        @include font-size("small-2", $lang);
        @include font-weight("medium", $lang);
      }
    }

    .btn {
      &.btn--text-lg {
        .btn__title {
          @include font-size("h6", $lang);
        }
      }
    }
  }
}

.footer {
  position: relative;
  overflow: hidden;
  background-color: $black;
  color: $white;

  .footer__content {
    padding: 4rem 0;

    @include media-breakpoint-down(sm) {
      padding: 2rem 0;
    }
  }

  .footer__address {
    margin-bottom: 0.55rem;
  }

  .footer__logo {
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
      max-height: 145px;

      @include media-breakpoint-down(lg) {
        max-height: 130px;
      }

      @include media-breakpoint-down(sm) {
        max-height: 110px;
      }
    }
  }

  .footer__nav {
    @include media-breakpoint-up(xl) {
      padding-left: 2rem;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 2.45rem;
    }
  }

  .footer__title {
    color: $yellow-ci;
    margin-bottom: 0.75rem;
  }

  .footer__block {
    margin-top: 2.6rem;
  }

  .footer__list {
    margin-bottom: 0.625rem;

    a {
      color: $white;
      text-decoration: none;
      @include transition();

      @include hover-focus-active {
        color: $yellow-ci;
      }
    }
  }

  .footer__tel {
    display: flex;
    margin-top: 2.75rem;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      margin:  1.75rem 0;
    }

    a {
      color: $white;
      text-decoration: none;
      @include transition();

      @include hover-focus-active {
        color: $yellow-ci;
      }
    }

    i {
      margin-right: 1.45rem;
      color: $yellow-ci;
    }

    &::after {
      content: "";
      position: absolute;
      left: -15%;
      width: 250px;
      height: 250px;
      border-radius: 250px;
      opacity: 0.3;
      background: radial-gradient(
        50% 50% at 50% 50%,
        $yellow-ci 0%,
        $yellow-ci 100%
      );
      filter: blur(75px);
      z-index: -1;
    }
  }

  .footer__email {
    margin-top: 2.75rem;

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0.75rem;
    }
  }

  .footer__copyright {
    padding: 1.25rem 0;
    border-top: 1px solid rgba($white, 0.2);

    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
}

.nav {
  &.nav--social {
    display: flex;
    list-style: none;
    gap: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 0;
    padding: 0;
    justify-content: center;

    .nav-link {
      padding: 0;
      color: $white;
      text-decoration: none;
      @include transition();

      i {
        font-size: px-to-rem(24);
      }

      @include hover-focus-active {
        color: $yellow-ci;
      }
    }
  }
}

.nav {
  &.nav--footer {
    display: block;
    columns: 1;

    .nav-link {
      padding: 0.45rem 0;

      @include hover-focus-active {
        color: $yellow-ci;
      }
    }
  }
}

.btn {
  &.btn--text-white {
    .btn__title {
      text-transform: none;
    }

    .btn__icon {
      i {
        font-size: px-to-rem(9);
      }
    }
  }

  &.btn--text-lg {
    .btn__icon {
      i {
        font-size: px-to-rem(22);

        @include media-breakpoint-down(lg) {
          font-size: px-to-rem(18);
        }

        @include media-breakpoint-down(sm) {
          font-size: px-to-rem(11);
        }
      }
    }
  }
}
</style>
